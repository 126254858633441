import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { FormattedMessage, intlShape, defineMessages } from 'react-intl';
import { CardContent } from '@material-ui/core';

const red500 = '#ff5723';

const localeMessages = defineMessages({
  mailaddress: {
    id: "Supporter.LoginDialog.MailAddress",
    defaultMessage: "Email-Adresse"
  },
  password: {
    id: "Supporter.LoginDialog.Password",
    defaultMessage: "Passwort"
  },
  logintitle: {
    id: "Supporter.LoginDialog.LoginTitle",
    defaultMessage: "Login"
}
});

class LoginDialog extends Component {
  static contextTypes = {
    intl: intlShape.isRequired,
  }

  state = {
    password: '',
    username: localStorage ? localStorage.getItem('username') : ''
  }

  submit(e) {
    e.preventDefault();
    this.props.onLogin(this.state.username, this.state.password);

    if (localStorage) {
      localStorage.setItem('username', this.state.username);
      localStorage.removeItem('language_changed');
    }
    this.setState({password: ''});
  }

  render() {
    const langChanged = (localStorage && localStorage.getItem('language_changed'));

    return (
      <div className="login" style={{margin: 'auto', textAlign: 'center', zIndex: 11}}>
        <img src={"Logo.png"} alt="" style={{
          maxWidth: '460px',
          marginBottom:'30px'
          }} />
        <Paper elevation={1}>
          <Card style={{backgroundColor: 'white'}}>
            <form action="" ref="form" method="" onSubmit={(e) => this.submit(e)}>
              <CardContent>
                {langChanged && 
                  <p style={{color: '#ff5723'}}>
                    <FormattedMessage
                      id="Supporter.LoginDialog.LanguageChanged"
                      defaultMessage="Sie benutzen {appname} nun in Deutsch. Bitte loggen Sie sich erneut ein."
                      values={{appname: this.props.appName}}
                    />
                  </p>}
                {this.props.authfail &&
                  <p style={{color: red500}}>
                    <FormattedMessage
                      id="Supporter.LoginDialog.LoginFailed"
                      defaultMessage="Login fehlgeschlagen"
                    />
                  </p>}
                {this.props.loggedout &&
                  <p style={{color: red500}}>
                    <FormattedMessage
                      id="Supporter.LoginDialog.LoggedOut"
                      defaultMessage="Sie wurden ausgeloggt"
                    />
                  </p>}
                <div>
                  <TextField
                    onInput={(e) => this.setState({username: e.target.value})}
                    value={this.state.username}
                    label={this.context.intl.formatMessage(localeMessages.mailaddress)}
                    style={{width:'100%'}}
                    margin="dense"
                  /><br/>
                  <TextField
                    label={this.context.intl.formatMessage(localeMessages.password)}
                    onInput={(e) => this.setState({password: e.target.value})}
                    value={this.state.password}
                    style={{width:'100%'}}
                    margin="dense"
                  />
                  <button type="submit" style={{display:'none'}}>
                    <FormattedMessage
                      id="Supporter.LoginDialog.LoginTitle"
                      defaultMessage="Login"
                    />
                  </button>
                </div>
              </CardContent>
              <CardActions style={{justifyContent:'center'}}>
                <Button
                  color="primary"
                  onClick={(e) => this.submit(e)}
                >
                  {this.context.intl.formatMessage(localeMessages.logintitle)}
                </Button>
              </CardActions>
            </form>
          </Card>
        </Paper>
      </div>
    )
  }
};

export default LoginDialog;
