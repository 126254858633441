import React, { Component } from 'react';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { FormattedMessage, intlShape, defineMessages } from 'react-intl';

const greenA700 = '#ff5723'
const localeMessages = {
  deny: {
    id: "Supporter.IncomingCall.Deny",
    defaultMessage: "Ablehnen"
  },
  accept: {
    id: "Supporter.IncomingCall.Accept",
    defaultMessage: "Annehmen"
  },
  endaccept: {
    id: "Supporter.IncomingCall.EndAccept",
    defaultMessage: "Annehmen und laufendes Gespräch beenden"
  },
  newcall: {
    id: "Supporter.IncomingCall.NewCall",
    defaultMessage: "Neuer Anruf für Sie ({name})"
  },
  ipinfo: {
    id: "Supporter.IncomingCall.IPInfo",
    defaultMessage: "IP: {ip} mit {agent}"
  },
};

class IncomingCall extends Component {
  static contextTypes = {
    intl: intlShape.isRequired,
  }

  render() {
    const actions = [
      <Button
        key="denybutton"
        color="primary"
        style={{color: 'red'}}
        onClick={() => this.props.deny(this.props.contact)}
      >
        {this.context.intl.formatMessage(localeMessages.deny)}
      </Button>,
      <Button
        key="acceptbutton"
        color="primary"
        style={{color: greenA700}}
        onClick={() => this.props.accept(this.props.contact)}
      >
        {this.props.calling ? this.context.intl.formatMessage(localeMessages.endaccept) : this.context.intl.formatMessage(localeMessages.accept)}
      </Button>
    ];

    const agent = this.props.contact.agent || {};
    const contact = this.props.contact;

    return (
      <Dialog
        open={true}
      >
        <DialogTitle style={{padding:'2em'}}>
          <div style={{verticalAlign: 'top', display: 'flex'}}>
            <Avatar
              style={{marginRight:'16px', float: 'left'}}
            >
              <AccountCircle style={{width:'100%', height:'100%'}}/>
            </Avatar>
            <span>
              <FormattedMessage
                id="Supporter.IncomingCall.NewCall"
                values={{
                  name: this.props.contact.name
                }}
                defaultMessage="Neuer Anruf für Sie ({name})"
              /><br/>
              <span style={{opacity:0.6, fontSize: '0.75em', whiteSpace: 'nowrap'}}>
                <FormattedMessage
                  id="Supporter.IncomingCall.IPInfo"
                  values={{
                    ip: contact.ip,
                    agent: (agent ? agent.browser.name : '') + " " + (agent ? agent.os.name : '')
                  }}
                  defaultMessage="IP: {ip} mit {agent}"
                /></span>
            </span>
          </div>
        </DialogTitle>
        <DialogActions>
          {actions}
        </DialogActions>
      </Dialog>
    );
  }
};

export default IncomingCall;
