
const muiTheme = {
  palette: {
    // primary: {main: 'rgb(0, 154, 60)'},
    primary: { main: '#ff5723' },
    secondary: { main: '#ff5723' },
    pickerHeaderColor: {main: '#ff5723'}
  }
};

export default muiTheme;