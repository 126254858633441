import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Snackbar from '@material-ui/core/Snackbar';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Button from '@material-ui/core/Button';
import { createMuiTheme } from '@material-ui/core/styles';

import ConversationList from './ConversationList';
import ConversationWindow from './ConversationWindow';
import LoginDialog from './LoginDialog';
import AccountMenu from './AccountMenu';
import IncomingCall from './IncomingCall';
import ConfirmContactDeleteDialog from './ConfirmContactDeleteDialog'
import ContactEditor from './ContactEditor';
import SupporterProfile from './SupporterProfile';
import Help from './Help';
import LiveView from './LiveView';

import { intlShape, defineMessages } from 'react-intl';

import defaultTheme from './defaultTheme'
import LanguageSwitcher from './LanguageSwitcher'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import { ThemeProvider } from '@material-ui/styles';

window.AudioContext = window.AudioContext || window.webkitAudioContext;
const greenA700 = '#ff5723';
const grey100 = '#F5F5F5';

const localeMessages = defineMessages({
  anonymous: {
    id: "Supporter.App.Anonymous",
    defaultMessage: "Anonym"
  },
  mailsent: {
    id: 'Supporter.App.MailSent',
    defaultMessage: 'E-Mail versendet'
  },
  mailsendfailed: {
    id: 'Supporter.App.MailSendFailed',
    defaultMessage: 'E-Mail-Versand fehlgeschlagen'
  },
  contactdeleted: {
    id: 'Supporter.App.ContactDeleted',
    defaultMessage: 'Kontakt {name} gelöscht'
  },
  contactdeletefailed: {
    id: 'Supporter.App.ContactDeleteFailed',
    defaultMessage: 'Kontakt {name} konnte nicht gelöscht werden'
  },
  contactsavefailed: {
    id: 'Supporter.App.ContactSaveFailed',
    defaultMessage: 'Änderungen an Kontakt {name} konnten nicht gespeichert werden'
  },
  contactchanged: {
    id: 'Supporter.App.ContactChanged',
    defaultMessage: 'Kontakt {name} geändert'
  },
  contactadded: {
    id: 'Supporter.App.ContactAdded',
    defaultMessage: 'Kontakt {name} hinzugefügt'
  },
  incomingCall: {
    id: 'Client.ClientIncomingCall.CallerMsg',
    defaultMessage: '{name} ruft an'
  },
  noWebcam: {
    id: "Shared.App.NoWebcam",
    defaultMessage: "Es wurde keine Webcam erkannt"
  },
  webcamBusy: {
    id: "Shared.App.WebcamBusy",
    defaultMessage: "Webcam ist belegt. Versuchen Sie es bitte erneut."
  },
  webcamDenied: {
    id: "Shared.App.WebcamDenied",
    defaultMessage: 'Sie müssen Zugriff auf die Webcam erlauben.'
  },
  webcamUnknownError: {
    id: "Shared.App.WebcamUnknownError",
    defaultMessage: 'Unbekannter Webcamfehler: {error}'
  },
  newContact: {
    id: "Supporter.App.NewContact",
    defaultMessage: 'Neuer Kontakt'
  },
  endCallForHelp: {
    id: "Supporter.App.EndCallForHelp",
    defaultMessage: "Bitte beenden Sie das Gespräch zur Anzeige der Hilfestellungen."
  },
  endCallForLink: {
    id: "Supporter.App.EndCallForLink",
    defaultMessage: "Bitte beenden Sie das Gespräch zur Anzeige Ihrer Links."
  },
  endCallForLiveView: {
    id: 'Supporter.App.EndCallForLiveView',
    defaultMessage: "Bitte beenden Sie das Gespräch zur Anzeige der LiveView."
  },
  showLinks: {
    id: 'Supporter.App.ShowLinks',
    defaultMessage: 'Meine Links anzeigen'
  },
  helpSupport: {
    id: 'Supporter.Help.Help',
    defaultMessage: 'Hilfe & Support'
  },
  connectedContacts: {
    id: 'Supporter.ContactList.OnlineContacts',
    defaultMessage: 'Verbundene Kontakte'
  },
  connectedGroups: {
    id: 'Supporter.ContactList.OnlineGroups',
    defaultMessage: 'Verbundene Gruppen'
  },
  passedConversations: {
    id: 'Supporter.ContactList.PastConversations',
    defaultMessage: "Vergangene Konversationen"
  },
  chatlink: {
    id: 'Supporter.AppointmentForm.Chatlink',
    defaultMessage: 'Ihr Chatlink auf {appname}'
  },
});

class Conversation {
  messages = [];
  contacts = [];
  offlineContacts = [];
  events = [];
  metadataInquiries = [];
  metadata = {};
  name = "";
  owner = "";
  group = false;
  online = false;

  constructor(owner, group, id, name) {
    this.owner = owner;
    this.group = group;
    this.id = id;
    this.name = name;
  }

  static getCallStats(conversation, cb) {
    const statPromises = conversation.contacts.map(contact => {
      return new Promise((resolve, reject) => {
        window.pc = contact.pc;
        if (contact.pc) {
          const localTrack = contact.pc.getSenders().find(t => t.track.kind === 'video');
          const remoteTrack = contact.pc.getReceivers().find(t => t.track.kind === 'video');
          const audioTrack = contact.pc.getReceivers().find(t => t.track.kind === 'audio');

          contact.pc.getStats(null).then(stats => {
            const now = Date.now() / 1000;
            const deltaTime = now - (contact.lastStats || (now - 1));
            contact.lastStats = now;

            let remoteFps = 0;
            let localFps = 0;
            let audioLevel = 0;

            for (const entry of stats) {
              if (entry[1].trackIdentifier) {
                if (localTrack && entry[1].trackIdentifier === localTrack.track.id && entry[1].framesSent) {
                  contact.localFps.unshift((entry[1].framesSent - contact.lastLocalFps) / deltaTime);
                  localFps = contact.localFps.reduce((a, c) => a + c) / contact.localFps.length;
                  contact.lastLocalFps = entry[1].framesSent;
                }
                else if (remoteTrack && entry[1].trackIdentifier === remoteTrack.track.id && entry[1].framesReceived) {
                  contact.remoteFps.unshift((entry[1].framesReceived - contact.lastRemoteFps) / deltaTime);
                  remoteFps = contact.remoteFps.reduce((a, c) => a + c) / contact.remoteFps.length;
                  contact.lastRemoteFps = entry[1].framesReceived;
                }
                else if (audioTrack && entry[1].trackIdentifier === audioTrack.track.id && "audioLevel" in entry[1]) {
                  contact.audioLevels.unshift(entry[1].audioLevel);
                  audioLevel = contact.audioLevels.reduce((a, c) => a + c) / contact.audioLevels.length;
                }
              }
            }
            
            resolve({local: localFps, remote: remoteFps, volume: audioLevel});
            return;
          })
          .catch(e => {
            console.log(e);
            resolve({local: 0, remote: 0, volume: 0});
          });
        }
        else {
          resolve({local: 0, remote: 0, volume: 0});
        }
      });
    });

    Promise.all(statPromises).then(r => {
      const stats = {};
      conversation.contacts.forEach((c, ix) => stats[c.id] = r[ix]);
      cb(stats);

      requestAnimationFrame(() => {
        conversation.contacts.forEach(c => {
          c.audioLevels = c.audioLevels.slice(0, 30);
          c.remoteFps = c.remoteFps.slice(0, 10);
          c.localFps = c.localFps.slice(0, 10);
        });
      });
    }).catch(e => {
      console.error(e);
    });
  }

  static addEvent(conversation, issuer, type) {
    const event = {
      type: type,
      from: issuer.id,
      time: new Date()
    };

    conversation.events.push(event);
  }

  static setContacts(conversation, newContacts, defaultAttributes) {
    // merges contacts into conversation
    // returns conversation with contacts updated,
    // the newly online contacts
    // and the now offline contacts
    const contactsNew = [];
    const contactsLeft = [];
    const contactsUpdated = [];
    defaultAttributes = defaultAttributes || {};

    const stillOnlineContacts = conversation.contacts.filter(s => {
      const stillOnline = newContacts.find(sNew => s.id === sNew.id);

      if (!stillOnline) {
        s.online = false;
        contactsLeft.push(s);
        return false;
      }
      else {
        return true;
      }
    });

    // check for contacts not in current contacts, "new" contacts
    newContacts.forEach(sNew => {
      let inContacts = conversation.contacts.find(s => s.id === sNew.id);

      if (inContacts) {
        inContacts = Object.assign({}, inContacts, sNew, {name: inContacts.name});
        contactsUpdated.push(inContacts);
      }
      else {
        sNew.name = "name" in sNew && sNew.name ? sNew.name : defaultAttributes.name;
        sNew.recoverCount = 0;
        sNew.audioLevels = [];
        sNew.remoteFps = [];
        sNew.localFps = [];
        sNew.lastLocalFps = 0;
        sNew.lastRemoteFps = 0;
        sNew.optimizeLocalStreamRequests = [];
        sNew.optimizeRemoteStreamRequests = [];
        sNew.audioContext = new AudioContext();
        sNew.audioOutStream = sNew.audioContext.createMediaStreamDestination();

        if (!conversation.name && !conversation.group) {
          conversation.name = sNew.name;
        }
        
        contactsNew.push(sNew);
      }
    });
    
    conversation.contacts = contactsNew.concat(contactsUpdated);
    conversation.offlineContacts = conversation.offlineContacts.concat(contactsLeft);

    conversation.online = (conversation.contacts.length !== 0);

    return {conversation, contactsNew, contactsLeft, contactsUpdated};
  }
}

class App extends Component {
  userMediaPromise = null;
  
  static contextTypes = {
    intl: intlShape.isRequired,
  }

  constructor(props) {
    super(props);

    window.app = this;
    this.socket = this.props.socket;
    this.callStatusTimeout = null;

    this.socket.once('connect', () => {
      this.setState(this.getInitialState(), () => {
        this.socket.emit('identify', {
          userAgent: window.navigator.userAgent,
        });
        this.readDevices();
      });

      this.socket.on('connect', () => {
        this.setState({connected: true});

        this.socket.emit('identify', {
          userAgent: window.navigator.userAgent,
        });
        this.readDevices();
        
        if (this.state.username && this.state.password) {
          this.requestAuthentication(this.state.username, this.state.password);
        }
      });
    });

    this.socket.on('disconnect', () => {
      this.setState({connected: false});
    });

    this.socket.on('authenticated', (user) => this.authenticated(user));
    this.socket.on('authfail', (user) => this.setState({authfail: true, user: null}));
    this.socket.on('logout', (user) => this.loggedout());
    this.socket.on('helprequest', (data) => console.log("Somebody has problems", data));
    this.socket.on('queue.v2', (queue) => this.updateConversations(queue));

    this.socket.on('pc', (msg) => this.processPcMsg(msg));
    this.socket.on('mailsent', () => this.showInfo(this.context.intl.formatMessage(localeMessages.mailsent)));
    this.socket.on('mailsendfailed', () => this.showInfo(this.context.intl.formatMessage(localeMessages.mailsendfailed)));
    this.socket.on('contactdeleted', (c) => this.showInfo(this.context.intl.formatMessage(localeMessages.contactdeleted, {name: c.name})));
    this.socket.on('deletecontactfailed', (c) => this.showInfo(this.context.intl.formatMessage(localeMessages.contactdeletefailed, {name: c.name})));
    this.socket.on('modifycontactfailed', (c) => this.showInfo(this.context.intl.formatMessage(localeMessages.contactsavefailed, {name: c.name})));
    this.socket.on('contactmodified', (c) => this.showInfo(this.context.intl.formatMessage(localeMessages.contactchanged, {name: c.name})));
    this.socket.on('contactcreated', (c) => this.showInfo(this.context.intl.formatMessage(localeMessages.contactadded, {name: c.name})));

    this.socket.on('users', (users) => {
      let groupUsers = [];

      if (users.length) {
        groupUsers = users;
      }

      this.setState({groupUsers});
    });

    const callSound = new Audio('incoming.mp3');
    callSound.loop = true;
    callSound.load();
    this.callSound = callSound;

    const notificationSound = new Audio('notification.mp3');
    notificationSound.loop = false;
    notificationSound.load();
    this.notificationSound = notificationSound;
    this.notificationSound.addEventListener('ended', () => this.notificationSound.currentTime = 0);

    if (window.Notification && Notification.permission !== "granted") {
      Notification.requestPermission(function (status) {
        if (Notification.permission !== status) {
          Notification.permission = status;
        }
      });
    }

    navigator.mediaDevices.ondevicechange = () => this.readDevices();

    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      user: null,
      conversations: [],
      staleConversations: [],
      calling: false,
      authfail: false,
      loggedout: false,
      roomnames: this.props.roomnames || ['nextcare'],
      activeConversation: null,
      showInfo: false,
      conversationOpen: null,
      incomingCallQueue: {},
      callSoundCanPlay: false,
      clientprefix: this.props.clientprefix || 'go.ms-preview',
      theme: this.props.theme || createMuiTheme(defaultTheme),
      videoDevices: [],
      audioDevices: [],
      speakerDevices: [],
      muteRemote: false,
      muteLocal: false,
      disableCam: false,
      activeCamDevice: 0,
      callStatus: null,
      recoverCountLocal: 0,
      languageSwitcherMenuEl: null,
      groupUsers: [],
      connected: true,
      username: "",
      password: ""
    };
  }

  componentWillUpdate(nP, nS) {
    const hasIncomingCall = Object.keys(nS.incomingCallQueue).length;
    const hadIncomingCall = Object.keys(this.state.incomingCallQueue).length;

    if ("contacts" in nS || "queue" in nS || "staleQueue" in nS || "recoverCountRemote" in nS) {
      throw new Error("Deprecated use of state prop");
    }

    try {
      if (hasIncomingCall) {
        if (!hadIncomingCall && this.callSound.paused) {
          this.callSound.currentTime = 0;
        }

        if (this.callSound.paused) {
          const playPromise = this.callSound.play();
          if (playPromise !== undefined) {
            playPromise.catch(error => {
              console.log("ERROR", error);
            });
          }
        }
      }
      else {
        this.callSound.pause();
      }
    } catch (e) {
      console.log(e);
    }
  }

  componentDidUpdate(pP, pS) {
    if (this.state.callStatus !== pS.callStatus) {
      this.slog(`Callstatus: ${this.state.callStatus}`);
    }
    
    if (this.callStatusTimeout !== null) {
      clearTimeout(this.callStatusTimeout);
    }

    if (['stopped', 'denied'].indexOf(this.state.callStatus) !== -1) {
      this.callStatusTimeout = setTimeout(() => {
        this.clearCallStatus();
      }, 3000);
    }
  }

  updateConversations(_newQueue) {
    let { owner, group, contacts, id, name } = _newQueue;
    const updatedConversations = this.state.conversations.slice(0);
    const deltaConversations = [];
    const contactDefaultAttributes = {name: this.context.intl.formatMessage(localeMessages.anonymous)};

    if (group) {
      // group queue
      let conversation = updatedConversations.find(g => g.id === id);

      if (!conversation) {
        conversation = new Conversation(owner, group, id, name);
        updatedConversations.unshift(conversation);
      }
      
      deltaConversations.push(Conversation.setContacts(conversation, contacts, contactDefaultAttributes));
    }
    else {
      // main queue
      // create/update conversation for every contact      
      contacts.forEach(contact => {
        let conversation = updatedConversations.find(g => g.id === contact.id);

        // add new conversations
        if (!conversation) {
          conversation = new Conversation(owner, group, contact.id, contact.name);
          updatedConversations.unshift(conversation);
        }
      });

      updatedConversations.filter(g => !g.group).forEach(conversation => {
        let contactIsOnline = contacts.find(c => conversation.id === c.id);

        // update contacts
        deltaConversations.push(Conversation.setContacts(conversation, contactIsOnline ? [contactIsOnline] : [], contactDefaultAttributes));
      });
    }

    deltaConversations.forEach(_c => {
      const {conversation, contactsNew, contactsLeft, contactsUpdated} = _c;

      contactsLeft.forEach(c => {
        Conversation.addEvent(conversation, c, 'offline');

        // remove incoming call from this user if any
        this.removeIncomingCall(c);
      });

      contactsNew.forEach(c => {
        c.online = true;
        Conversation.addEvent(conversation, c, 'online');

        this.establishDataChannel(c);
      });

      contactsUpdated.forEach(c => {
        this.establishDataChannel(c);
      });
    });

    this.setState({
      conversations: updatedConversations
    }, () => {
      if (this.state.calling) {
        const callingConversation = updatedConversations.find(c => c.id === this.state.calling.id);

        if (callingConversation && callingConversation.contacts.length === 0) {
          this.stopCall();
          this.clearCall();
        }
      }
    });
  }

  getInviteLink(date, time, group, user) {
    let host = '';

    if (typeof this.props.clientprefix === 'function') {
      host = this.props.clientprefix(this.state.user);
    }
    else {
      host = window.location.host.split('.');
      host[0] = this.props.clientprefix;
      host = host.join('.');
    }

    var d = date ? date : new Date();
    var t = time ? time : new Date();
    d.setHours(t.getHours());
    d.setMinutes(t.getMinutes());
    d.setSeconds(t.getSeconds());

    var appointment = d;
    
    return window.location.protocol + '//' + host + '?u=' + (user ? user.id : this.state.user.id) +
      (date ? '&a=' + appointment.getTime() : '') +
      (group ? '&g=' + btoa(group) : '')
  }

  sendInviteToUser(link, options) {
    var maildata = {
      from: this.props.mailsender,
      to: this.state.user.email,
      subject: `${this.context.intl.formatMessage(localeMessages.chatlink, {appname: this.props.appName})}${options.withGroup ? `: ${options.groupName}` : ''}`,
      text: `${link}${options.withGroup ? ` (${options.groupName})` : ''}`
    };

    this.sendMail(maildata);
  }

  copyLink(el) {
    el.querySelector('input').select();
    
    if (document.execCommand('copy')) {
      this.showInfo('Link kopiert');
    }
  }
  
  preview(x, y, url) {
    window.open(url, '_blank', 'width=375,height=667,top=' + y + ',left=' + x)
  }

  getContactById(cId) {
    let contact = null;
    this.state.conversations.find(c => {
      contact = c.contacts.find(s => s.id === cId);
      return contact;
    });
    return contact;
  }

  getConversationByContactId(cId) {
    return this.state.conversations.find(c => c.contacts.some(s => s.id === cId));
  }

  setupLocalStream(video, _camDevice, cb) {
    let devices = this.state.videoDevices;
    let camDevice = _camDevice !== null ? _camDevice : this.state.activeCamDevice;
    const user = this.state.user;

    if (camDevice === null || camDevice >= devices.length) {
      camDevice = 0;
    }

    video = video || {
      width: 1920,
      height: 1080,
      frameRate: 60,
      facingMode: "user"
    };

    // set up local audio playback
    if (!this.state.user.audioContext) {
      user.audioContext = new AudioContext();
      user.audioGain = this.state.user.audioContext.createGain();
      user.audioDestination = this.state.user.audioContext.createMediaStreamDestination()
      user.audioGain.connect(this.state.user.audioDestination);
      user.audioPlayback = new Audio();
      user.audioPlayback.srcObject = this.state.user.audioDestination.stream;
      user.audioPlayback.play();
      user.currentSpeaker = this.state.user.audioPlayback.sinkId || 'default';

      window.user = user;
      this.setState({user});
    }

    if (user.stream && user.stream.readyEvent !== 'ended') {
      if (cb) cb(user.stream);
      return;
    }

    if (this.userMediaPromise !== null) {
      this.userMediaPromise.then(cb);
      return;
    }

    this.readDevices();

    this.userMediaPromise = new Promise((resolve, reject) => {
      navigator.mediaDevices.getUserMedia({
        audio: true,
        video: video
      })
      .then(stream => {
        const user = this.state.user;
        this.userMediaPromise = null;

        console.log("Webcam received");
        this.slog(`Access to webcam granted`);

        user.stream = stream;
        const videoTracks = stream.getVideoTracks();
        const audioTracks = stream.getAudioTracks();
        
        if (videoTracks.length && videoTracks[0].getSettings) {
          user.currentCam = videoTracks[0].getSettings().deviceId;
        }

        if (audioTracks.length && audioTracks[0].getSettings) {
          user.currentMic = audioTracks[0].getSettings().deviceId;
        }

        this.setState({user});

        resolve(stream);
      })
      .catch((e) => {
        this.userMediaPromise = null;
        reject(e);
        console.log(e, video);
        this.elog(`Setup local stream ${e.name}`);

        if ("constraint" in e) {
          switch (e.constraint) {
            case 'width':
              video.width = video.width / 2;

              if (video.width < 480) {
                delete video.width;
              }
              break;
            case 'height':
              video.height = video.height / 2;

              if (video.height < 270) {
                delete video.height;
              }
              break;
            case 'facingMode':
              delete video.facingMode;
              break;
            case 'frameRate':
              video.frameRate = video.frameRate / 2;
              if (video.frameRate < 15) {
                delete video.frameRate;
              }
              break;
            default:
              delete video.frameRate;
              delete video.facingMode;
              this.setupLocalStream(video, camDevice++, cb);
              return;
          }

          this.setupLocalStream(video, camDevice, cb);
        }
        else {
          switch (e.name) {
            case 'NotFoundError':
            case 'DevicesNotFoundError':
              alert(this.context.intl.formatMessage(localeMessages.noWebcam));
              break;
            case 'AbortError':
            case 'NotReadableError':
            case 'SourceUnavailableError':
              alert(this.context.intl.formatMessage(localeMessages.webcamBusy));
              break;
            case 'PermissionDeniedError':
            case 'SecurityError':
            case 'NotAllowedError':
              alert(this.context.intl.formatMessage(localeMessages.webcamDenied));
              break;
            default:
              console.log(`Webcam error: ${e.name}`);
              alert(this.context.intl.formatMessage(localeMessages.webcamUnknownError, {error: e.name}));
              break;
          }

          this.stopCall();
        };
      });
    });
    
    this.userMediaPromise.then(cb);
  }

  processPcMsg(message) {
    const contact = this.getContactById(message.from);
    let pc = null;

    console.log("[<- PC Com <-]", message);

    if (!contact) {
      console.log("No contact found for", message.from, this.conversations);
      return;
    }

    console.log("Incoming PC", message, "Data channel is", contact.datachannel ? contact.datachannel.readyState : 'not existing');

    if (message.desc || message.candidate) {
      if (message.datachannel) {
        if (!contact.datapc) {
          this.establishDataChannel(contact);
        }

        pc = contact.datapc;
      }
      else {
        if (!contact.pc) {
          this.establishPeerconnection(contact);
        }

        pc = contact.pc;
      }
    }

    setTimeout(() => {
      if (message.desc) {
        const desc = message.desc;

        console.log("Setting remote description", desc);
        pc.setRemoteDescription(desc)
          .then((e) => {
            console.log("Remote description set", desc);
          })
          .catch((e) => {
            console.log(contact.id, contact, e);
            // alert("Remote description could not be set");
          });
      }
      else if (message.candidate) {
        pc.addIceCandidate(message.candidate).catch((e) => {
          console.log(contact.id, contact, e);
          // alert("Ice Candidate could not be set");
        });
      }
      else {
        this.processDcMessage(message, contact);
      }
    }, 16);
  }

  stopStreams() {
    if (this.state.calling) {
      this.state.calling.contacts.forEach(contact => {
        if (contact.stream) {
          contact.stream.getTracks().forEach((s) => {
            contact.stream.removeTrack(s);
            s.stop()
          });
        }

        if (contact.pc) {
          contact.pc.removeStream(contact.stream);

          if (this.state.user.stream) {
            contact.pc.removeStream(this.state.user.stream);
          }
        }
      });
    }

    if (this.state.user.stream) {
      this.state.user.stream.getTracks().forEach((s) => {
        this.state.user.stream.removeTrack(s);
        s.stop()
      });
    }
  }

  clearCall() {
    this.setState({
      calling: null,
      muteRemote: false,
      muteLocal: false,
      disableCam: false,
      callStatus: null
    });
  }

  stopCallToContact(contact, hideStop) {
    this.sendMessage(contact, {action: 'stop', hide: hideStop}, 'call');
    if (contact.pc) {
      contact.pc.close();
      delete contact.pc;

      contact.recoverCount = 0;
    }
  }

  stopCall(cb, hideStop) {
    this.setState({
      recoverCountLocal: 0
    });

    if (this.state.calling) {

      if (!hideStop) {
        this.setState({
          callStatus: 'stopping'
        });
      }

      this.state.calling.contacts.forEach(contact => {
        this.stopCallToContact(contact);
      });

      setTimeout(() => {
        this.stopStreams();
        this.clearCall();
        delete this.state.user.stream;

        if (!hideStop) {
          this.setState({
            callStatus: 'stopped'
          });
        }
        
        if (cb) {
          cb();
        }
      }, 16);
    }
    else {
      if (cb) {
        cb();
      }
    }
  }

  muteRemote() {
    let toggle = !this.state.muteRemote;

    if (this.state.calling) {
     this.state.user.audioGain.gain.setValueAtTime(!toggle, this.state.user.audioContext.currentTime);
    }

    this.setState({
      muteRemote: toggle,
      conversations: this.state.conversations
    });

    this.ulog(`Remote ${toggle ? 'muted' : 'unmuted'}`);
  }

  muteLocal() {
    let toggle = !this.state.muteLocal;
    
    if (this.state.user.stream) {
      this.state.user.stream.getAudioTracks().forEach(s => s.enabled = !toggle);
    }

    this.setState({muteLocal: toggle});

    this.ulog(`Local ${toggle ? 'muted' : 'unmuted'}`);
  }

  disableCam() {
    let toggle = !this.state.disableCam;

    if (this.state.calling) {
      this.state.calling.contacts.forEach(c => {
        if (c.pc) {
          const senders = c.pc.getSenders().filter(c => c.track.kind === 'video');
          senders.forEach(t => {
            t.track.enabled = !toggle;
          });
        }
      });
      this.state.user.stream.getVideoTracks().forEach(s => s.enabled = !toggle);
    }

    this.setState({disableCam: toggle});
    
    this.ulog(`Cam ${toggle ? 'disabled' : 'enabled'}`);
  }

  optimizeVideo(contact, remoteOrLocal, currentFps) {
    if (remoteOrLocal === 'remote') {
      let now = Date.now() / 1000;
      contact.optimizeRemoteStreamRequests = contact.optimizeRemoteStreamRequests.filter(p => now - p.time < 20);

      contact.optimizeRemoteStreamRequests.push({performance: currentFps / 15, time: now});

      if (contact.optimizeRemoteStreamRequests.length < 5) {
        return;
      }
      
      let currentPerformance = Math.min(1, contact.optimizeRemoteStreamRequests.map(s => s.performance).reduce((a, b) => a + b) / contact.optimizeRemoteStreamRequests.length);
      let lastPerformance = contact.remotePerformance || 0.5;

      if (currentPerformance < 1.0) {
        currentPerformance = Math.max(currentPerformance / 2, lastPerformance / 2, 0.1);
      }
      else if (lastPerformance >= 1.0) {
        return;
      }

      contact.remotePerformance = currentPerformance;
      contact.optimizeRemoteStreamRequests = [];

      // remote video is too big
      const constraints = {
        video: {
          width: Math.floor(1920 * currentPerformance),
          height: Math.floor(1920 * currentPerformance * 0.5625),
          frameRate: 60,
          facingMode: 'user'
        }
      };

      console.log("Performing", currentPerformance >= 1 ? 'UPGRADE' : 'DOWNGRADE', "of remote video stream to", contact.id, "current performance:", currentPerformance, "new Dimensions:", constraints);
      
      this.sendMessage(contact, {
        action: 'optimizevideo',
        constraints: constraints
      }, 'call');
    }
    else {
      let now = Date.now() / 1000;
      contact.optimizeLocalStreamRequests = contact.optimizeLocalStreamRequests.filter(p => now - p.time < 20);

      contact.optimizeLocalStreamRequests.push({performance: currentFps / 15, time: now});

      if (contact.optimizeLocalStreamRequests.length < 5) {
        return;
      }

      let currentPerformance = Math.min(1, contact.optimizeLocalStreamRequests.map(s => s.performance).reduce((a, b) => a + b) / contact.optimizeLocalStreamRequests.length);
      let lastPerformance = contact.localPerformance || 0.5;
      contact.localPerformance = currentPerformance;

      if (currentPerformance < 1.0) {
        currentPerformance = Math.max(currentPerformance / 2, lastPerformance / 2, 0.1);
      }
      else if (lastPerformance >= 1.0) {
        return;
      }

      contact.optimizeLocalStreamRequests = [];

      // local video is too big
      const constraints = {
        video: {
          width: Math.floor(1920 * currentPerformance),
          height: Math.floor(1920 * currentPerformance * 0.5625),
          frameRate: 60,
          facingMode: 'user'
        }
      };

      if (this.state.user.currentCam) {
        constraints.video.deviceId = {
          exact: this.state.user.currentCam
        };
      }

      console.log("Performing", currentPerformance >= 1 ? 'UPGRADE' : 'DOWNGRADE', "of local video stream to", contact.id, "current performance:", currentPerformance, "new Dimensions:", constraints);

      const requestMedia = (constraints) => {
        navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          let videoTrack = stream.getVideoTracks()[0];

          if (this.state.calling) {
            const c = contact;
            const user = this.state.user;

            if (!c.pc) return;
            if (!c.pc.getSenders) return;

            const sender = c.pc.getSenders().find(s => s.track.kind === videoTrack.kind);

            var tracks = user.stream.getVideoTracks();
            console.log("Usertracks before replacing: " + tracks.length);

            if (!sender || !sender.replaceTrack) return;
            sender.replaceTrack(videoTrack);

            var tracks = user.stream.getVideoTracks();
            console.log("Usertracks after replacing: " + tracks.length);

            if (videoTrack.getSettings) {
              user.currentCam = videoTrack.getSettings().deviceId;
            }
            /*
            user.stream.getVideoTracks().forEach(v => {
              v.stop();
              user.stream.removeTrack(v);
            });*/
            user.stream.addTrack(videoTrack);

            this.setState({user});
          }
        })
        .catch(function(err) {
          console.error('Could not switch video track:', err);
          if ("constraint" in err && err.constraint && err.constraint in constraints) {
            switch (err.constraint) {
              case 'width':
                constraints.width = constraints.width / 2;
                break;
              case 'height':
                constraints.height = constraints.height / 2;
                break;
              case 'frameRate':
                constraints.frameRate = constraints.frameRate / 2;
                break;
              case 'facingMode':
                delete constraints.facingMode;
                break;
              default:
                return;
            }
            requestMedia(constraints);
          }
        });
      };
      requestMedia(constraints);
    }
  }

  onSelectSpeaker(id) {
    const user = this.state.user;

    if (user.audioPlayback) {
      user.audioPlayback.setSinkId(id);
      user.currentSpeaker = id;

      this.setState({
        user: user,
        muteRemote: false
      });
    }
  }

  onSelectMic(id) {
    navigator.mediaDevices
    .getUserMedia({
      audio: {
        deviceId: {
          exact: id
        }
      }
    })
    .then((stream) => {
      let audioTrack = stream.getAudioTracks()[0];
      const user = this.state.user;
      
      if (this.state.calling ) {
        this.state.calling.contacts.forEach(c => {
          if (!c.pc) return;

          var mediaStreamSource = c.audioContext.createMediaStreamSource(stream);

          mediaStreamSource.connect(c.audioOutStream); 
          c.mediaStreamSource = mediaStreamSource;
        });

        if (audioTrack.getSettings) {
          user.currentMic = audioTrack.getSettings().deviceId;
        }

        user.stream.getAudioTracks().forEach(a => {
          a.stop();
          user.stream.removeTrack(a);
        });
        user.stream.addTrack(audioTrack);

        this.setState({
          user: this.state.user,
          muteLocal: false
        });
      }
    })
    .catch(function(err) {
      console.error('Could not switch audio track:', err);
    });
  }

  onSelectCam(id, constraints) {
    constraints = constraints || {
      width: 1920,
      height:1080,
      frameRate: 60
    };

    constraints.deviceId = {exact: id};

    navigator.mediaDevices
    .getUserMedia({
      video: constraints
    })
    .then((stream) => {
      let videoTrack = stream.getVideoTracks()[0];
      const user = this.state.user;

      if (this.state.calling ) {
        this.state.calling.contacts.forEach(c => {
          if (!c.pc) return;
          if (!c.pc.getSenders) return;

          const sender = c.pc.getSenders().find(s => s.track.kind === videoTrack.kind);

          if (!sender || !sender.replaceTrack) return;
          
          sender.replaceTrack(videoTrack);
        });

        if (videoTrack.getSettings) {
          user.currentCam = videoTrack.getSettings().deviceId;
        }

        user.stream.getVideoTracks().forEach(v => {
          v.stop();
          user.stream.removeTrack(v);
        });
        user.stream.addTrack(videoTrack);

        this.setState({
          user: user,
          disableCam: false
        });
      }
    })
    .catch((err) => {
      console.error('Could not switch video track:', err);

      if ("constraint" in err) {
        switch (err.constraint) {
          case 'width':
            constraints.width = constraints.width / 2;

            if (constraints.width < 480) {
              delete constraints.width;
            }
            break;
          case 'height':
            constraints.height = constraints.height / 2;

            if (constraints.height < 270) {
              delete constraints.height;
            }
            break;
          case 'facingMode':
            delete constraints.facingMode;
            break;
          case 'frameRate':
            constraints.frameRate = constraints.frameRate / 2;
            if (constraints.frameRate < 15) {
              delete constraints.frameRate;
            }
            break;
          default:
            delete constraints.frameRate;
            delete constraints.facingMode;
            this.onSelectCam(id, constraints);
            return;
        }

        this.onSelectCam(id, constraints);
      }
    });
  }

  switchCam() {
    let newCD = this.state.activeCamDevice + 1;

    if (newCD >= this.state.videoDevices.length) {
      newCD = 0;
    }

    if (this.state.calling) {
      Conversation.addEvent(this.state.calling, this.state.user, 'change');
    }

    this.setupLocalStream(null, newCD);
    
    this.ulog(`Cam switched`);
  }

  recoverStream(kind, contact) {
    if (kind === 'local') {
      console.log("Not trying to recover local stream");
    }
    else if (kind === 'remote' && contact) {
      const conversation = this.getConversationByContactId(contact.id);
      if (this.state.calling && this.state.calling.contacts.find(c => c.id === contact.id)) {
        if (contact.pc) {

          if (contact.recoverCountLocal > 1) {
            console.log("Reconnect");
            this.reconnect(contact);
          }
          else {
            Conversation.addEvent(conversation, contact, 'recover');
            this.switchCam();
  
            contact.callStatus = 'recovering';
            contact.recoverCount = contact.recoverCount + 1;
  
            this.setState({
              conversations: this.state.conversations
            });
          }
        }
      }
    }
  }

  sendMessage(contact, data, type) {
    if (type === 'chat' || type === 'id') {
      if (contact.datapc && contact.datachannel && contact.datachannel.readyState === 'open') {
        console.log("Sending chat message via datachannel");
        contact.datachannel.send(
          btoa(unescape(encodeURIComponent(JSON.stringify(Object.assign({}, data, {type: type})))))
        );
      }
      else {
        console.log("Data channel not open, could not deliver chat message");
      }
    }
    else {
      // send via server
      data.for = contact.id;
      data.type = type;
      this.sendPcCom(data);
      console.log("Sent msg via server", data);
    }
  }

  sendChatMessage(conversation, msg) {
    let data = {
      from: this.state.user.id,
      text: msg,
      id: this.state.user.id + "-" + conversation.id + "-" + parseInt(Math.random() * 100000),
      time: new Date()
    };

    const messages = (conversation.messages || []).slice();
    messages.push(data);
    conversation.messages = messages;

    this.setState({conversations: this.state.conversations});

    conversation.contacts.forEach(contact => {
      if (contact && contact.datachannel) {
        this.sendMessage(contact, data, 'chat');
        this.ulog(`Chat message sent to ${contact.id}`);
      }
    });
  }

  sendPcCom(data) {
    this.socket.emit('pc', data);
    console.log('[-> PC Com ->]', data);
  }

  issueCall(conversation) {
    Conversation.addEvent(conversation, this.state.user, 'calling');

    this.setState({
      callStatus: 'calling',
      ringingUser: conversation
    });

    conversation.contacts.forEach(contact => {
      this.sendMessage(contact, {action: 'calling'}, 'call');
    });
  }

  issueStopCall() {
    if (this.state.calling) {
      Conversation.addEvent(this.state.calling, this.state.user, 'callend');

      this.setState({
        calling: this.state.calling
      });
    }
    this.stopCall();
  }

  abortCall() {
    if (this.state.ringingUser) {
      Conversation.addEvent(this.state.ringingUser, this.state.user, 'abort');

      this.state.ringingUser.contacts.forEach(contact => {
        this.sendMessage(contact, {action: 'abort'}, 'call');
      });
    }

    this.setState({
      callStatus: null
    });
  }

  streamsRunning() {
    this.setState({
      callStatus: 'established',
    });

    this.slog(`Streams running`);

    const conversationOpen = this.state.conversationOpen;

    if (!conversationOpen.streamsRunning) {
      conversationOpen.streamsWereRunning = true;

      this.setState({conversationOpen});

      this.checkCallMetadata(conversationOpen);
    }
  }

  checkCallMetadata(conversation) {
    const contact = conversation.contacts.length > 0 ? conversation.contacts[0] :
      (conversation.offlineContacts && conversation.offlineContacts.length ? conversation.offlineContacts.length : false);
    if (contact !== false && conversation.streamsWereRunning) {
      const requiredMetadata = Object.keys(this.props.callMetadata).filter(k => {
        const metadataEntry = this.props.callMetadata[k];

        return (metadataEntry.required && (!contact.metadata || !contact.metadata[k]));
      });

      if (requiredMetadata.length) {
        const conversationOpen = this.state.conversationOpen;

        conversationOpen.metadataInquiries = conversationOpen.metadataInquiries || [];

        const metadataInquiryTime = conversationOpen.metadataInquiries.length ?
          conversationOpen.metadataInquiries[0].time :
          new Date();

        requiredMetadata.forEach(m => {
          let existing = conversationOpen.metadataInquiries.find(c => c.metadataId === m);

          let conditions = this.props.callMetadata[m].showIf;
          if (conditions) {
            let isShown = Object.keys(conditions).every(key => {
              let currentValue = conversationOpen.metadata[key];
              return String(currentValue) === String(conditions[key]);
            });

            if (!isShown) {
              conversationOpen.metadataInquiries = conversationOpen.metadataInquiries.filter(c => c.metadataId !== m);
              return;
            }
          }

          if (existing) {
            return;
          }
          else {
            conversationOpen.metadataInquiries.push({
              type: 'inquiry',
              from: 'system',
              time: metadataInquiryTime,
              metadataId: m,
              metadata: this.props.callMetadata,
              values: conversationOpen.metadata
            });
          }
        });

        this.setState({conversationOpen});
      }
    }
  }

  clearCallStatus() {
    this.setState({
      callStatus: null
    });
  }

  call(contact) {
    console.log("Calling", contact.id);
    const conversation = this.getConversationByContactId(contact.id);
    this.setState({calling: conversation, conversationOpen: conversation}, () => {
      if (!contact.pc) {
        this.establishPeerconnection(contact);
      }

      this.sendMessage(contact, {action: 'callstart'}, 'call');

      setTimeout(() => {
        this.setupLocalStream(null, null, (stream) => {
          console.log("Got local stream");

          const tracks = stream.getTracks();

          if (this.state.calling && this.state.calling.id === conversation.id) {
            if (contact.pc) {
              console.log("Adding local tracks to pc");
              var mediaStreamSource = contact.audioContext.createMediaStreamSource(stream);

              mediaStreamSource.connect(contact.audioOutStream); 
              contact.mediaStreamSource = mediaStreamSource;
              
              tracks.forEach(track => {
                if (track.kind === 'video') {
                  contact.pc.addTrack(track, stream);
                }
                else if (track.kind === 'audio') {
                  contact.pc.addTrack(contact.audioOutStream.stream.getTracks()[0], stream);
                }
              });

              console.log("Added other remote audio tracks to pc");

              conversation.contacts.forEach(remoteContact => {
                if (remoteContact.id !== contact.id && remoteContact.stream) {
                  if (remoteContact.stream.getAudioTracks().length > 0) {
                    var mediaStreamSource = contact.audioContext.createMediaStreamSource(remoteContact.stream);
                    mediaStreamSource.connect(contact.audioOutStream);
                  }
                }
              });

              if (contact.negotiationneeded) {
                this.sendOffer(contact);
              }
              else {
                console.log("Got local tracks, waiting for client");
              }
            }
          }
          else {
            console.log("not sending offer, no caller");
          }

          this.setState({
            conversations: this.state.conversations,
          });
        });
      });
    });
  }

  establishDataChannel(contact) {
    console.log("Establishing Datachannel RTC with", contact.id);

    if (contact.datapc && contact.datapc.iceConnectionState !== 'failed' && contact.datapc.iceConnectionState !== 'closed') {
      console.log("Datachannel exists,   skipping", contact.pc);
      return;
    }

    const pc = new RTCPeerConnection({
      iceServers: this.props.iceServers
    });

    contact.datapc = pc;

    let evtl = (evt) => {
      console.log("Datachannel RTC", evt.type, JSON.parse(JSON.stringify(evt)));
      this.setState({conversations: this.state.conversations});
    };
    pc.onconnectionstatechange = evtl;
    pc.oniceconnectionstatechange = evtl;
    pc.onicegatheringstatechange = evtl;
    pc.onidentityresult = evtl;
    pc.onpeeridentity = evtl;
    pc.onsignalingstatechange = (e) => {
      console.log("Signaling state", pc.signalingState);
      if (pc.signalingState === 'closed') {
        delete contact.datapc;
      }
    };

    setTimeout(() => {
      this.sendOffer(contact, true);
    }, 16);

    pc.onnegotiationneeded = (evt) => {
      console.log("Datachannel Negotiationneeded", contact.id, "skipping");
    };

    pc.onicecandidate = (evt) => {
      if (evt.candidate) {
        console.log("Received local ice candidate", evt.candidate);
        this.sendPcCom({
          'for': contact.id,
          'candidate': evt.candidate,
          'datachannel': true
        });
      }
    };

    // create a data channel
    if ("createDataChannel" in pc) {
      let dataChannel = pc.createDataChannel("conn", {
        ordered: true
      });

      dataChannel.onerror = function (error) {
        contact.state = 'error';
        this.setState({queue: this.state.queue});
      };

      dataChannel.onmessage = (event) => {
        console.log("Remote DC message", event);
        var data = JSON.parse(decodeURIComponent(escape(atob(event.data))));
        this.processDcMessage(data, contact);
      };

      dataChannel.onopen = function () {
        console.log("Datachannel to", contact, "opened");
      };

      dataChannel.onclose = function () {
        console.log("The Data Channel is Closed");
      };

      contact.datachannel = dataChannel;
    }

    contact.messages = [];
    contact.metadataInquiries = [];
    contact.metadata = {};
    contact.events = (contact.events ? contact.events : []);
  }

  establishPeerconnection(contact) {
    const conversation = this.getConversationByContactId(contact.id);
    console.log("Establishing RTC with", contact.id);

    if (contact.pc && contact.pc.iceConnectionState !== 'failed' && contact.pc.iceConnectionState !== 'closed') {
      return;
    }

    const pc = new RTCPeerConnection({
      iceServers: this.props.iceServers
    });

    contact.pc = pc;

    let evtl = (evt) => {
      console.log(evt.type, JSON.parse(JSON.stringify(evt)));
      this.setState({conversations: this.state.conversations});
    };
    contact.pc.onconnectionstatechange = evtl;
    contact.pc.oniceconnectionstatechange = evtl;
    contact.pc.onicegatheringstatechange = evtl;
    contact.pc.onidentityresult = evtl;
    contact.pc.onpeeridentity = evtl;
    contact.pc.onsignalingstatechange = (e) => {
      console.log("Signaling state", pc.signalingState);
      if (pc.signalingState === 'closed') {
        delete contact.pc;
      }
    };

    contact.pc.onnegotiationneeded = (evt) => {
      console.log("Negotiationneeded", contact.id);
      this.sendOffer(contact);
    };
    
    pc.onicecandidate = (evt) => {
      if (evt.candidate) {
        console.log("Received local ice candidate", evt.candidate);
        this.sendPcCom({
          'for': contact.id,
          'candidate': evt.candidate
        });
      }
    };

    // once remote track arrives, show it in the remote video element
    pc.ontrack = (evt) => {
      contact.stream = evt.streams[0];
      const track = evt.track;
      console.log("Got remote stream for", contact.id, evt.streams, evt.track);
      
      if (track.kind === 'audio') {
        conversation.contacts.forEach(remoteContact => {
          if (remoteContact.id !== contact.id && remoteContact.pc) {
            var mediaStreamSource = remoteContact.audioContext.createMediaStreamSource(contact.stream);
            mediaStreamSource.connect(remoteContact.audioOutStream);
          }
        });

        // add to local audio playback
        const localStreamSource = this.state.user.audioContext.createMediaStreamSource(contact.stream);
        localStreamSource.connect(this.state.user.audioGain);

        contact.audioTrack = track;
      }
      else if (track.kind === 'video') {
        contact.videoTrack = track;
      }

      const conversations = this.state.conversations.slice();
      this.setState({
        conversations: conversations,
        conversationOpen: this.state.conversationOpen,
        calling: this.state.calling
      });
    };
  }

  sendOffer(contact, datachannel) {
    let pc = datachannel ? contact.datapc : contact.pc;

    if (!datachannel) {
      contact.negotiationneeded = false;
    }

    if (pc) {
      pc.createOffer().then((desc) => {
        console.log("Offer created, setting local description", desc);
        pc.setLocalDescription(desc)
        .then(() => {
          console.log("Local description set");
          this.sendPcCom({
            'for': contact.id,
            'desc': desc,
            'datachannel': datachannel
          });
        })
        .catch((e) => {
          console.log(contact.id, contact, e);
          // alert("Remote description could not be set");
        });
      },
        function(e) { console.log("Offer creation failed", e); },
      );
    }
    else {
      console.log("Tried to send offer on non-existing pc", contact, datachannel);
    }
  }

  processDcMessage(data, contact) {
    const conversation = this.getConversationByContactId(contact.id);

    if (data.type === 'chat') {
      const messages = conversation.messages.slice();
      data.time = new Date(); // send time to arrival time
      messages.push(data);
      conversation.messages = messages;
      this.setState({conversations: this.state.conversations});
      this.slog(`Chat message received from ${contact.id}`);

      // forward to all participants
      conversation.contacts.forEach(c => {
        if (c.id !== data.from) {
          this.sendMessage(c, data, 'chat');
        }
      });
      
      if (this.notificationSound.currentTime === 0) {
        this.notificationSound.play();
      }
    }
    else if (data.type === 'id') {
      contact.name = data.name;
      
      if (!conversation.group) {
        conversation.name = data.name;
      }

      this.setState({conversations: this.state.conversations});
      this.slog(`ID message received from ${contact.id}, ${JSON.stringify(data)}`);

      // forward to all participants
      conversation.contacts.forEach(c => {
        if (c.id !== data.from) {
          this.sendMessage(c, {
            name: contact.name,
            id: contact.id
          }, 'id');
        }
      });
    }
    else if (data.type === 'call') {
      switch (data.action) {
        case 'init':
          if (conversation.group) {
            // participant is ready
            if (this.state.calling && this.state.calling.contacts.some(c => c.id === contact.id)) {
              this.stopCallToContact(contact, true);
              setTimeout(() => {
                this.call(contact);
              });
            }
          }
          else {
            // participant is calling
            Conversation.addEvent(conversation, contact, 'calling');
            this.addIncomingCall(contact);
          }
          break;
        case 'negotiate':
          console.log("Remote negotiation requested");
          Conversation.addEvent(conversation, contact, 'change');
          contact.negotiationneeded = true;
          
          this.setState({
            calling: this.state.calling
          });

          if (this.state.user.stream) {
            this.sendOffer(contact);
          }
          else {
            console.log("Local stream not ready yet, not sending offer");
          }
          break;
        case 'stop':
          if (this.state.calling && this.state.calling.contacts.some(c => c.id === contact.id)) {
            if (!data.hide) {
              Conversation.addEvent(conversation, contact, 'callend');

              if (this.state.calling.contacts.length <= 1) {
                this.stopCall(() => {}, data.hide);
                this.setState({
                  callStatus: 'stopped'
                });
              }
              else {
                this.stopCallToContact(contact, data.hide);
              }
              setTimeout(() => {
                this.setState({conversations: this.state.conversations});
              })
            }
          }

          break;
        case 'recover':
          if (contact.recoverCount > 1) {
            console.log("Reconnect");
            this.reconnect(contact);
          }
          else {
            Conversation.addEvent(conversation, contact, 'recover');
            this.switchCam();

            contact.callStatus = 'recovering';
            contact.recoverCount = contact.recoverCount + 1;

            this.setState({
              conversations: this.state.conversations
            });
          }
          break;
        case 'streamsrunning':
          contact.recoverCount = 0;
          contact.callStatus = 'established';
          
          this.setState({
            conversations: this.state.conversations
          });
          break;
        case 'reconnect':
          this.reconnect(contact);
          break;
        case 'accepted':
          Conversation.addEvent(conversation, contact, 'accepted');
          this.call(contact);
          if (!conversation.group) {
            this.setState({
              callStatus: 'accepted'
            });
          }
          break;
        case 'denied':
          Conversation.addEvent(conversation, contact, 'denied');
          if (!conversation.group) {
            this.setState({
              callStatus: 'denied'
            });
          }
          break;
        case 'abort':
          Conversation.addEvent(conversation, contact, 'abort');
          this.removeIncomingCall(contact);
          break;
        default:
          console.log("Unknown action", data.action);
          break;
      }
    }
  }

  reconnect(contact) {
    if (contact) {
      this.stopCallToContact(contact, true);

      setTimeout(() => {
        if (this.state.calling && this.state.calling.contacts.some(c => c.id === contact.id)) {
          this.call(contact);
        }
      }, 16);
    }
    else {
      let conversation = this.state.calling;
      this.stopCall(() => {
        if (conversation) {
          conversation.contacts.forEach(contact => this.call(contact));
        }
      }, true);
    }
  }

  readDevices() {
    console.log("Reading devices");
    let video = [];
    let audio = [];
    let speakers = [];
    navigator.mediaDevices.enumerateDevices()
      .then((devices) => {
        devices.forEach(d => {
          if (d.kind === 'videoinput') {
            video.push(d);
          }
          else if (d.kind === 'audioinput') {
            audio.push(d);
          }
          if (d.kind === 'audiooutput') {
            speakers.push(d);
          }
        });
        
        this.setState({videoDevices: video, audioDevices: audio, speakerDevices: speakers});

        this.slog(`Videodevices: ${video.length}, Audiodevices: ${audio.length}, Speakers: ${speakers.length}`);
      });
  }

  slog(msg) {
    setTimeout(() => {
      this.socket.emit('syslog', msg);
    }, 16);

    var args = Array.prototype.slice.call(arguments);
    args.unshift('Syslog:');
    console.log.apply(console, args);
  }

  elog(msg) {
    var args = Array.prototype.slice.call(arguments);
    setTimeout(() => {
      this.socket.emit('errorlog', JSON.stringify(args));
    }, 16);

    args.unshift('Errorlog:');
    console.log.apply(console, args);
  }

  ulog(msg) {
    setTimeout(() => {
      this.socket.emit('actionlog', msg);
    }, 16);

    var args = Array.prototype.slice.call(arguments);
    args.unshift('Actionlog:');
    console.log.apply(console, args);
  }

  showConversation(conversation) {
    this.setState({
      conversationOpen: conversation,
      showHelp: false,
      showLiveView: false
    });
  }

  addIncomingCall(contact) {
    const conversation = this.getConversationByContactId(contact.id);

    if (this.state.calling && conversation.id === this.state.calling.id) {
      this.acceptCall(contact);
      return;
    }

    if (window.Notification && Notification.permission === "granted") {
      const n = new Notification(this.props.appName, {
        body: this.context.intl.formatMessage(localeMessages.incomingCall, {name: contact.name}),
        lang: this.props.locale,
        icon: 'favicon-96x96.png',
        badge: 'favicon-96x96.png'
      });

      n.addEventListener('click', () => window.focus());
      contact.notification = n;
    }

    const incomingCallQueue = this.state.incomingCallQueue;
    incomingCallQueue[contact.id] = contact;

    this.setState({incomingCallQueue});
  }

  removeIncomingCall(contact) {
    contact = this.state.incomingCallQueue[contact.id];

    if (!contact) {
      return false;
    }

    if (contact.notification) {
      contact.notification.close();
    }

    delete this.state.incomingCallQueue[contact.id];

    this.setState({
      incomingCallQueue: this.state.incomingCallQueue
    });
  }

  acceptCall(contact) {
    const conversation = this.getConversationByContactId(contact.id);

    this.sendMessage(contact, {action: 'accepted'}, 'call');
    Conversation.addEvent(conversation, this.state.user, 'accepted');
    this.removeIncomingCall(contact);

    if (this.state.calling && this.state.calling.id !== conversation.id) {
      this.stopCall(() => {
        this.call(contact);
      });
    }
    else {
      this.call(contact);
    }
  }

  denyCall(contact) {
    const conversation = this.getConversationByContactId(contact.id);

    this.sendMessage(contact, {action: 'denied'}, 'call');
    Conversation.addEvent(conversation, this.state.user, 'denied');
    this.removeIncomingCall(contact);
  }

  authenticated(user) {
    this.setState({
      user: user
    });
  }

  logout() {
    this.socket.emit('logout');
    window.location.reload();
  }

  loggedout() {
    if (this.state.calling) {
      this.stopCall();
      this.clearCall();
    }
    this.setState(this.getInitialState());
    
    if (this.state.user && this.state.user.stream) {
      this.state.user.stream.getTracks().forEach((s) => s.stop());
    }

    this.setState(Object.assign({}, this.getInitialState(), {
      loggedout: true,
      user: null
    }));

    this.callSound.pause();
  }

  sendMail = (data) => {
    this.socket.emit('mail', data);
  }

  showInfo = (msg) => {
    this.setState({
      showInfo: msg
    });
  }

  hideInfo = () => {
    this.setState({
      showInfo: false
    });
  }

  requestAuthentication(username, password) {
    this.setState({
      loggedout: false,
      authfail: false,
      username: username,
      password: password
    }, () => {
      this.socket.emit('authenticate', {username: username.toLowerCase(), password: password});
      localStorage.setItem('username', username);
    });
  }

  showContactEditor(contact) {
    this.setState({
      editContact: contact
    });
  }

  showConfirmContactDelete(contact) {
    this.setState({
      confirmContactDeletion: contact
    });
  }

  deleteContact(contact) {
    this.socket.emit('deletecontact', contact);
  }

  saveContact(contact) {
    this.socket.emit('savecontact', contact);
  }

  newContact(attributes) {
    const contact = Object.assign({
      name: this.context.intl.formatMessage(localeMessages.newContact)
    }, attributes)

    this.setState({
      editContact: contact
    });
  }

  showLiveView() {
    if (this.state.calling) {
      this.setState({
        showInfo: this.context.intl.formatMessage(localeMessages.endCallForLiveView)
      });
    }
    else {
      this.setState({
        conversationOpen: null,
        showLiveView: true,
        showHelp: false
      });
    }
  }

  showHelp() {
    if (this.state.calling) {
      this.setState({
        showInfo: this.context.intl.formatMessage(localeMessages.endCallForHelp)
      });
    }
    else {
      this.setState({
        conversationOpen: null,
        showHelp: true,
        showLiveView: false,
      });
    }
  }

  showProfile() {
    if (this.state.calling) {
      this.setState({
        showInfo: this.context.intl.formatMessage(localeMessages.endCallForLink)
      });
    }
    else {
      this.setState({
        conversationOpen: null,
        showHelp: false,
        showLiveView: false,
      });
    }
  }

  setLocale(lang) {
    if (window.localStorage) {
      window.localStorage.setItem('language', lang);
      window.localStorage.setItem('language_changed', true);
      window.location.reload();
    }
  }

  render() {
    const incomingCalls = Object.values(this.state.incomingCallQueue).map((contact) => {
      return <IncomingCall
        key={contact.id}
        accept={(contact) => this.acceptCall(contact)}
        deny={(contact) => this.denyCall(contact)}
        calling={this.state.calling}
        contact={contact} />
    });

    const groups = this.state.conversations.filter(g => g.group);
    const nonGroups = this.state.conversations.filter(g => !g.group);
    const offlineNonGroups = nonGroups.filter(c => !c.online);
    const hasLiveView = !!(this.state.groupUsers.length > 1);
    const canCall = this.state.user && (!hasLiveView || (this.state.groupUsers.find(u => u.id === this.state.user.id) || {}).roles.indexOf('caller') !== -1);

    return (
      <ThemeProvider theme={this.state.theme}>
        <div className={"outerShell" + (this.state.user ? ' loggedin': '') + (this.state.calling || this.state.callStatus ? " calling" : '')} style={{
          display:'flex',
          flexDirection: 'column',
          height: '100vh'}}>
          <div style={{zIndex: 10, display: 'flex', flexDirection: 'column', height: '100vh'}}>
            <AppBar
              className="appbar"
              style={{backgroundColor: 'white', opacity: this.state.user ? 1 : 0, boxShadow: 'inset 0px -15px 90px rgba(0, 0, 0, 0.85)'}}
              position="static">
              <Toolbar>
                <img className="logo" style={{height:'44px'}} src={"Logo.png"} />
                {
                this.state.user ?
                  <div>
                    {hasLiveView &&
                    <Button
                        onClick={() => this.showLiveView()}
                        style={{color:'white'}}>
                        Live
                      </Button>
                    }
                    {canCall && <Button
                      onClick={() => this.showProfile()}
                      style={{color:'white'}}>
                      {this.context.intl.formatMessage(localeMessages.showLinks)}
                    </Button>}
                    {canCall && <Button
                      onClick={() => this.showHelp()}
                      style={{color:'white'}}>
                      {this.context.intl.formatMessage(localeMessages.helpSupport)}
                    </Button>}
                    {this.props.availableLocales.length > 1 && 
                    <Button
                      style={{color:'white'}}
                      onClick={(e) => this.setState({languageSwitcherMenuEl: e.currentTarget})}>
                      {this.props.locale}
                      </Button>}
                    <AccountMenu
                      user={this.state.user}
                      onLogout={this.logout.bind(this)}
                    />
                    <Menu
                      transformOrigin={{horizontal: 'right', vertical: 'top'}}
                      anchorEl={this.state.languageSwitcherMenuEl}
                      anchorOrigin={{horizontal: 'right', vertical: 'top'}}
                      onClose={() => this.setState({languageSwitcherMenuEl: null})}
                      open={!!this.state.languageSwitcherMenuEl}
                    >
                      {
                      this.props.availableLocales.map(locale => {
                        return (
                          <MenuItem onClick={() => this.setLocale(locale.id)}
                            key={locale.id}
                            className={this.props.locale === locale.id ? 'active' : ''} data-lang={locale.id}>
                            {locale.label}
                      </MenuItem>
                        )
                      })
                      }
                    </Menu>
                  </div>
                  : <span></span>}
              </Toolbar>
            </AppBar>
            {this.state.user &&
              <div className={"app" + (this.state.calling || this.state.callStatus ? " calling" : '')}
                style={{
                  flex: 1,
                  display:'flex'
                }}
              >
                {canCall && <Paper className="contentArea" style={{
                  display:'flex', flex: 1}}>
                  <div className="contactList" style={{borderRight: '1px solid #ccc'}}>
                    <div
                      className="contactheader"
                      onClick={() => {
                        this.setState({conversationOpen: null, showHelp: false, showLiveView: false})
                      }}
                      style={{
                        backgroundColor: 'rgb(245, 245, 245)',
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer'
                      }}
                    >
                      <div className="name">
                        <Avatar
                          style={{display:'inline-block', marginRight: '16px'}}
                        >
                          <AccountCircle style={{width:'100%', height:'100%'}} />
                        </Avatar>
                        <div style={{display:'inline-block'}}>
                          <div style={{display:'inline-block'}}>{this.state.user.name}</div>
                          <div style={{marginTop:'0.2em'}}>
                            <span style={{color: (this.state.user.online ? greenA700 : 'red')}}>
                              {this.state.user.online ? 'Online' : 'Offline'}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {!!groups.length && <ConversationList
                      disableEdits={true}
                      title={this.context.intl.formatMessage(localeMessages.connectedGroups)}
                      activeConversation={this.state.conversationOpen}
                      user={this.state.user}
                      conversations={groups.reverse()}
                      onConversationClick={this.showConversation.bind(this)} />}
                    <ConversationList
                      disableEdits={true}
                      title={this.context.intl.formatMessage(localeMessages.connectedContacts)}
                      activeConversation={this.state.conversationOpen}
                      user={this.state.user}
                      conversations={nonGroups.filter(c => c.online).reverse()}
                      onConversationClick={this.showConversation.bind(this)} />
                    {!!offlineNonGroups.length &&
                      <ConversationList
                        disableEdits={true}
                        title={this.context.intl.formatMessage(localeMessages.passedConversations)}
                        user={this.state.user}
                        activeConversation={this.state.conversationOpen}
                        conversations={offlineNonGroups.reverse()}
                        onConversationClick={this.showConversation.bind(this)} />
                    }
                  </div>
                  {this.state.conversationOpen && <div style={{
                      flex: 3,
                      backgroundColor: grey100,
                      display: 'flex',
                      flexDirection: 'column',
                      overflow: 'auto',
                    }}
                    className="mainContent">
                    {this.state.conversationOpen &&
                      <ConversationWindow
                        reconnect={(c) => this.reconnect(c)}
                        user={this.state.user}
                        calling={this.state.calling}
                        messages={this.state.conversationOpen.messages || []}
                        metadataInquiries={this.state.conversationOpen.metadataInquiries || []}
                        events={this.state.conversationOpen.events || []}
                        onMessage={(to, msg) => this.sendChatMessage(to, msg)}
                        conversation={this.state.conversationOpen}
                        onError={(kind) => this.recoverStream(kind)}
                        getInviteLink={(date, time, group) => this.getInviteLink(date, time, group)}
                        copyLink={this.copyLink.bind(this)}
                        preview={this.preview.bind(this)}
                        sendInviteToUser={this.sendInviteToUser.bind(this)}
                        onCall={() => this.issueCall(this.state.conversationOpen)}
                        onMessagesUpdate={(messages) => {
                          const conversationOpen = this.state.conversationOpen;
                          conversationOpen.messages = messages;
          
                          this.setState({conversationOpen});
                        }}

                        onStreamsRunning={() => this.streamsRunning()}
                        callStatus={this.state.callStatus}
                        onMuteRemote={() => this.muteRemote()}
                        onMuteLocal={() => this.muteLocal()}
                        onStopCall={() => this.issueStopCall()}
                        onDisableCam={() => this.disableCam()}
                        onSelectSpeaker={(id) => this.onSelectSpeaker(id)}
                        onSelectCam={(id) => this.onSelectCam(id)}
                        onSelectMic={(id) => this.onSelectMic(id)}
                        onSwitchCam={() => this.switchCam()}
                        currentSpeaker={this.state.user.currentSpeaker}
                        currentMic={this.state.user.currentMic}
                        currentCam={this.state.user.currentCam}
                        clearCallStatus={() => this.clearCallStatus()}
                        onAbortCall={() => this.abortCall()}
                        optimizeVideo={(contact, remoteOrLocal, currentFps) => this.optimizeVideo(contact, remoteOrLocal, currentFps)}
                        muteRemote={this.state.muteRemote}
                        muteLocal={this.state.muteLocal}
                        disableCam={this.state.disableCam}
                        audioDevices={this.state.audioDevices}
                        videoDevices={this.state.videoDevices}
                        speakerDevices={this.state.speakerDevices}
                        
                        locale={this.props.locale}
                        callMetadata={this.props.callMetadata}
                        callMetadataValues={this.state.conversationOpen.metadata}
                        onChangeCallMetadata={(k, v) => {
                          const conversationOpen = this.state.conversationOpen;
                          conversationOpen.metadata[k] = v;

                          const values = {};
                          Object.keys(conversationOpen.metadata).forEach(k => {
                            values[k] = {
                              type: this.props.callMetadata[k].type,
                              title: this.props.callMetadata[k].title['de']
                            };

                            switch (values[k].type) {
                              case 'multiple':
                                values[k]['values'] = conversationOpen.metadata[k].map(ix => this.context.intl.formatMessage({
                                  id: "metadata." + k + ".values." + ix
                                })).join(', ');
                                break;
                              case 'list':
                                values[k]['values'] = this.context.intl.formatMessage({
                                  id: "metadata." + k + ".values." + conversationOpen.metadata[k]
                                });
                                break;
                              default:
                                values[k]['values'] = conversationOpen.metadata[k];
                            }
                          });
                          this.socket.emit('metadata', [conversationOpen.id, values]);

                          this.setState({conversationOpen}, () => this.checkCallMetadata(conversationOpen));
                        }}
                        />}
                  </div>}
                  <div className="incomingCalls">
                    {incomingCalls}
                  </div>
                </Paper>}
                {!this.state.conversationOpen && <div className="mainContent" style={{overflow: 'auto', flex: 3, padding: '10px 15px', marginRight: '15px'}}>
                  {canCall && !this.state.showHelp && !this.state.showLiveView &&
                    <SupporterProfile
                      appName={this.props.appName}
                      locale={this.props.locale}
                      user={this.state.user}
                      clientprefix={this.state.clientprefix}
                      getInviteLink={(date, time, group) => this.getInviteLink(date, time, group)}
                      copyLink={this.copyLink.bind(this)}
                      preview={this.preview.bind(this)}
                      sendInviteToUser={this.sendInviteToUser.bind(this)}
                      sendMail={this.sendMail}
                      showInfo={this.showInfo}
                      mailTheme={this.state.theme}
                    />
                  }
                  {this.state.showHelp &&
                    <Help />
                  }
                  {(!canCall || this.state.showLiveView) &&
                    <LiveView
                      groupUsers={this.state.groupUsers}
                      user={this.state.user}
                      getInviteLink={(date, time, group, user) => this.getInviteLink(date, time, group, user)}
                      preview={this.preview.bind(this)}
                    />}
                </div>}
              </div>}
            </div>
          {!this.state.user && 
            <LoginDialog
              locale={this.props.locale}
              authfail={this.state.authfail}
              appName={this.props.appName}
              loggedout={this.state.loggedout}
              onLogin={(username, password) => this.requestAuthentication(username, password)} />
          }
          {!this.state.user && <LanguageSwitcher availableLocales={this.props.availableLocales} setLocale={this.setLocale} locale={this.props.locale} />}
          {this.state.confirmContactDeletion &&
            <ConfirmContactDeleteDialog
              contact={this.state.confirmContactDeletion}
              onClose={() => this.setState({confirmContactDeletion:null})}
              onDelete={() => {
                this.deleteContact(this.state.confirmContactDeletion);
                this.setState({confirmContactDeletion:null});
              }}
            />}
          {this.state.editContact &&
            <ContactEditor
              contact={JSON.parse(JSON.stringify(this.state.editContact))}
              onClose={() => this.setState({editContact:null})}
              onSave={(c) => {
                this.saveContact(c);
                this.setState({editContact:null});
              }}
            />}
          <Snackbar
            open={!!this.state.showInfo}
            message={this.state.showInfo}
            autoHideDuration={4000}
            onClose={this.hideInfo}
          />
          <Snackbar
            open={!this.state.connected}
            message={'Disconnected'}
            autoHideDuration={4000}
          />
        </div>
      </ThemeProvider>
    );
  }
}

export { App, Conversation };