import React, { Component } from 'react';
import './Chat.css'

import { Bubble, Send } from './Icons'
import { intlShape, FormattedMessage, defineMessages } from 'react-intl';
import MetadataInquiry from './MetadataInquiry';

const eventLabels = defineMessages({
  'abort': {
    id: 'Shared.Chat.CallCancelled',
    defaultMessage: 'Anruf abgebrochen'
  },
  'calling': {
    id: 'Shared.Chat.CallStarted',
    defaultMessage: 'Anruf gestartet'
  },
  'callend': {
    id: 'Shared.Chat.CallEnded',
    defaultMessage: 'Anruf beendet'
  },
  'accepted': {
    id: 'Shared.Chat.CallAccepted',
    defaultMessage: 'Anruf angenommen'
  },
  'denied': {
    id: 'Shared.Chat.CallDenied',
    defaultMessage: 'Anruf abgelehnt'
  },
  'online': {
    id: 'Shared.Chat.Online',
    defaultMessage: 'Online'
  },
  'offline': {
    id: 'Shared.Chat.Offline',
    defaultMessage: 'Offline'
  }
});

const otherMessages = defineMessages({
  'placeHolderWriteMessage': {
    id: 'Shared.Chat.SendMessage',
    defaultMessage: 'Schreiben Sie eine Nachricht'
  },
  'you': {
    id: 'Shared.Chat.You',
    defaultMessage: 'Sie'
  }
});

class Chat extends Component {
  static contextTypes = {
    intl: intlShape.isRequired,
  }

  state = {
    composeMessage: ''
  }

  constructor(props) {
    super(props);
    
    this.messageBottom = React.createRef();
  }

  onSubmitMessage(e) {
    e.preventDefault();

    if (!this.state.composeMessage.length) {
      return;
    }
    this.props.onMessage(this.state.composeMessage);
    this.setState({composeMessage: ''});

    this.refs.text.focus();
  }

  componentDidUpdate() {
    this.messageBottom.scrollIntoView({ behavior: "smooth" });
  }

  render() {
    const messageCopy = this.props.messages.slice();
    const unreadMessages = messageCopy.filter(m => !m.read);

    if (unreadMessages.length) {
      unreadMessages.forEach(m => m.read = true);
      if (this.props.onMessagesUpdate) {
        this.props.onMessagesUpdate(messageCopy);
      }
    }

    const log = this.props.events.filter(e => e.type in eventLabels).concat(this.props.messages).concat(this.props.metadataInquiries).sort((a, b)=> a.time.getTime() - b.time.getTime());

    const logElements = log.map((m,i) => {
      const isMessage = "text" in m;

      if (!isMessage && m.type === 'inquiry') {
        return <div
          key={i}
          className="message"
          style={{margin:'1em auto', textAlign: 'center'}}
        >
          <MetadataInquiry
            metadataId={m.metadataId}
            metadata={m.metadata}
            values={m.values}
            onChange={this.props.onMetadataChange}
          />
        </div>;
      }
      else {
        const isLocal = (m.from === this.props.local.id) || m.from.id === this.props.local.id;
        const author = isLocal ? this.context.intl.formatMessage(otherMessages.you) : (this.props.remotes.find(r => r.id === m.from) || {}).name;  
        const text = isMessage ? m.text : (this.context.intl.formatMessage(eventLabels[m.type]) + ', ' + m.time.toLocaleTimeString('de-DE'));
        
        const urlRegex = new RegExp(/(https?):(\/\/(((([a-z]|\d|-|\.|_|~|[\x00A0-\xD7FF\xF900-\xFDCF\xFDF0-\xFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?((\[(|(v[\da-f]{1,}\.(([a-z]|\d|-|\.|_|~)|[!\$&'\(\)\*\+,;=]|:)+))\])|((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|(([a-z]|\d|-|\.|_|~|[\x00A0-\xD7FF\xF900-\xFDCF\xFDF0-\xFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=])*)(:\d*)?)(\/(([a-z]|\d|-|\.|_|~|[\x00A0-\xD7FF\xF900-\xFDCF\xFDF0-\xFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*|(\/((([a-z]|\d|-|\.|_|~|[\x00A0-\xD7FF\xF900-\xFDCF\xFDF0-\xFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\x00A0-\xD7FF\xF900-\xFDCF\xFDF0-\xFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)|((([a-z]|\d|-|\.|_|~|[\x00A0-\xD7FF\xF900-\xFDCF\xFDF0-\xFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\x00A0-\xD7FF\xF900-\xFDCF\xFDF0-\xFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)|((([a-z]|\d|-|\.|_|~|[\x00A0-\xD7FF\xF900-\xFDCF\xFDF0-\xFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)){0})(\?((([a-z]|\d|-|\.|_|~|[\x00A0-\xD7FF\xF900-\xFDCF\xFDF0-\xFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\xE000-\xF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\x00A0-\xD7FF\xF900-\xFDCF\xFDF0-\xFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?/, 'gi');
        let match = [];

        const parts = [];
        let last = 0;
        let partNum = 0;
        while((match = urlRegex.exec(text)) !== null) {
          const matchPosition = match.index;
          const url = match[0];
          
          parts.push(<span key={'t-' + partNum++}>{text.substring(last, matchPosition)}</span>);
          parts.push(<a key={'t-' + partNum++} href={url} target="_blank">{url}</a>);

          last = urlRegex.lastIndex;
        }
        parts.push(<span key={'t-' + partNum++}>{text.substring(last)}</span>);

        const showAuthor = (i === 0 || log[i - 1].from !== m.from);
        return <div
        key={i}
        className={"message " + (isMessage ? '' : 'event ') + (isLocal ? 'local' : 'remote') + (showAuthor ? ' showAuthor' : '')}>
          <div>
            <Bubble className="bubble" />
            <div className="author">{author}</div>
            <div className="content">
              {parts}
            </div>
          </div>
        </div>;
      };
    });

    return (
      <div className="chat" style={{display:'flex', flexDirection: 'column', height: '100%'}}>
        <div className="messages" ref="messages">
          {logElements}
          <div className="message-bottom" ref={(el) => { this.messageBottom = el; }}></div>
        </div>
        <div className="compose">
          <form action="" method="" onSubmit={e => this.onSubmitMessage(e)}>
            <input
              autoComplete="off"
              type="text"
              ref="text"
              onChange={() => {}}
              placeholder={this.context.intl.formatMessage(otherMessages.placeHolderWriteMessage)}
              id={this.props.local.id + '-composefield'}
              onInput={(e) => this.setState({'composeMessage': e.target.value})}
              value={this.state.composeMessage}
            />
            <button type="submit">
              <div>
                <Send />
                <span>
                  <FormattedMessage
                    id="Shared.Chat.Send"
                    defaultMessage="Senden"
                  />
                </span>
              </div>
            </button>
          </form>
        </div>
      </div>
    )
  }
};

export default Chat;